import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import './Cart.scss'
import CartItem from '../CartItem/CartItem'
import { scrollToTop, getSearchCompletePath } from '../../shared/utils'
import { redirectToSpecifiedUrl } from '../../redux/actions'
import { extractQueryParams } from '../../services/urlStateParser'
import { Col, Row } from 'reactstrap'
import OverviewInfoBox from '../OverviewInfoBox/OverviewInfoBox'

class Cart extends React.Component {
  constructor (props) {
    super(props)
    this.handleCheckoutClick = this.handleCheckoutClick.bind(this)
  }

  componentDidMount () {
    scrollToTop()
  }

  handleCheckoutClick () {
    const searchLocation = this.props.searchLocation

    this.props.redirectToSpecifiedUrl(
      getSearchCompletePath({ searchLocation }, this.props.queryParams)
    )
  }

  render () {
    const { t } = this.props
    const params = this.props.location.search || '?'
    return (
      <Row className='ml-0 mr-0 pl-3 pr-3'>
        <Col xs={12} sm={7} md={8} lg={9} xxl={10}>
          <div className='c-container cart-wrapper'>
            <div className='c-content-title-1'>
              <h3 className='c-center c-font-lowercase'>{t('your_selection')}</h3>
            </div>
            <div
              style={{ padding: '35px 0px 35px 0px' }}
              className='c-content-box'
            >
              <div className='container'>
                <div className='c-shop-cart-page-1'>
                  <div className='row c-cart-table-title ml-0 mr-0'>
                    <div className='col-md-4 c-cart-image'>
                      <h3 className='cart-item-title'>
                        {t('image')}
                      </h3>
                    </div>
                    <div className='col-md-6 c-cart-desc'>
                      <h3 className='cart-item-title'>
                        {t('description')}
                      </h3>
                    </div>
                    <div className='col-md-2 c-cart-total'>
                      <h3 className='cart-item-title'>
                        {t('remove_item')}
                      </h3>
                    </div>
                  </div>
                  {this.props.cartItems && this.props.cartItems.length > 0 ? (
                    this.props.cartItems.map((item, idx) => (
                      <CartItem key={idx} item={item} />
                    ))
                  ) : (
                    <div
                      style={{ justifyContent: 'center' }}
                      className='row c-cart-table-row cartItemContainer ml-0 mr-0'
                    >
                      <p style={{ fontSize: '1.2rem' }}>{t('cart_is_empty')}</p>
                    </div>
                  )}
                  <Row className='cart-buttons mr-0 ml-0'>
                    <Col className='p-0' xs={12} md={4}>
                      <Link className='defaultButton font-white c-cart-float-l btnContinueSearching' to={`/results/${params}`}>
                        {t('continue_searching')}
                      </Link>
                    </Col>
                    <Col className='p-0' xs={12} md={4}>
                      <button
                        onClick={this.handleCheckoutClick}
                        className='defaultButton font-white c-cart-float-r client-theme--bg'
                      >
                        {this.props.searchSession
                          ? t('add_to_meeting')
                          : t('create_meeting')}
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className='d-none d-sm-block' sm={5} md={4} lg={3} xxl={2}>
          <OverviewInfoBox
            queryParams={this.props.queryParams}
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cartItems: state.app.cartItems,
    searchSession: state.app.searchSession,
    searchLocation: state.app.searchLocation,
    queryParams: extractQueryParams(ownProps)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    redirectToSpecifiedUrl: payload => dispatch(redirectToSpecifiedUrl(payload))
  }
}

Cart.propTypes = {
  redirectToSpecifiedUrl: PropTypes.func,
  searchLocation: PropTypes.object,
  searchSession: PropTypes.object
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Cart))
